import Cookies from 'cookies-ts';
import jwt_decode from 'jwt-decode';
import { userType } from '@/apps/authentication/types/AuthTypes';
const cookies = new Cookies();
const TOKEN = 'AUTH_TOKEN';

export function setToken(token: string) {
  if (token) {
    cookies.set(TOKEN, token);
  }
}
export async function logout() {
  cookies.set(TOKEN, null, { expires: -60 * 60 * 8 });
}
export function getToken() {
  return cookies.get(TOKEN);
}

export function isLoggedIn() {
  return !!getToken();
}
export function getUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

export function encryptUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

export function checkCookieExpiration(): boolean {
  if (getToken() == null) return true;
  const decoded: userType = jwt_decode(getToken() as string);
  const expiringTimeInMilliseconds = decoded.exp * 1000 - Date.now();
  const expiringTimeInMinutes = expiringTimeInMilliseconds / (1000 * 60);
  if (expiringTimeInMinutes <= 2) return true;
  return false;
}
export function decodeToken() {
  const decoded: userType = jwt_decode(getToken() as string);
  return decoded;
}
