<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-3
        rounded-lg
        lg:mr-4
        cursor-pointer
        py-3
        px-4
        hover:bg-[#FFF0EA]
        w-full
        line-clamp-1
        group
      "
      :class="item?.label === activeRoute ? 'bg-[#FFF0EA]' : ''"
    >
      <div
        :title="item?.label"
        v-if="!store.showAll"
        class="w-full flex justify-center"
      >
        <slot></slot>
      </div>
      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          hidden
          lg:block
          whitespace-nowrap
          group-hover:text-[#dd5928]
        "
        :class="item?.label === activeRoute ? 'O400' : 'N700'"
        v-if="store.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { useStore } from '../store';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInvoiceStore } from '@/apps/invoice-manager/store/invoiceStore';
const route = useRoute();
const { activeRoute } = storeToRefs(useInvoiceStore());

const store = useStore();
const props = defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>
  }
});
</script>
<style scoped></style>
