import invoiceManagerRoutes from '@/apps/invoice-manager/router/invoiceManagerRoutes';
import settingRoutes from '@/apps/settings-config/router/settingRoutes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '../apps/authentication/components/PageNotFound.vue';
import { getToken, getUserid, isLoggedIn, logout } from '../services/auth';
import { encodeUrlPath } from '@/helpers/utils';

const route: Array<RouteRecordRaw> = [
  {
    path: '/logout',
    name: 'logout',
    alias: '/logout',
    component: () => '',
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next('/login');
    }
  },
  {
    path: '/login',
    name: 'login',
    alias: '/login',
    component: () =>
      (window.location.href = 'https://amalitech-sso.amalitech-dev.net/login'),
    meta: { auth: false }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '../apps/authentication/components/ForgotPassword.vue'
      )
  },
  {
    path: '/sending-email',
    name: 'SendingEmail',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/SendingEmail.vue'
      )
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ChangePassword.vue'
      )
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/views/createPassword.vue'
      )
  },
  {
    path: '/myglobalcomponentsarehere',
    name: 'ShowGlobal',
    component: () =>
      import(/* webpackChunkName: "global" */ '@/ui-kit/GlobalComponents.vue')
  },
  {
    path: '/legacy',
    name: 'legacy',
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '../apps/authentication/components/ForgotPassword.vue'
      )
  },

  {
    path: '/sending-email',
    name: 'SendingEmail',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/SendingEmail.vue'
      )
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ChangePassword.vue'
      )
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ResetPassword.vue'
      )
  },
  {
    path: '/learnmore',
    name: 'Learn More',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../apps/invoice-manager/components/LearnMore.vue'
      )
  },

  {
    path: '/migration-service',
    name: 'Migration',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../apps/migration-service/views/MigrationPage.vue'
      )
  },
  {
    path: '/add-query/:id',
    name: 'Query',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../apps/migration-service/views/AddQuery.vue'
      )
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;
const isInvoiceRoutesEnabled = !env.VUE_APP_INVOICE_MANAGER_ROUTES_ENABLED;

export const routes = route
  .concat(isInvoiceRoutesEnabled ? invoiceManagerRoutes : [])
  .concat(settingRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query['app-token']) {
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    next('/login');
  } else {
    next();
  }
});

export default router;
