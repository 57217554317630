<template>
  <Transition name="slide">
    <div
      :class="store.showAll ? 'lg:w-[18rem]' : 'md:w-[6rem]'"
      class="
        transition-all
        duration-300
        ease-linear
        settings
        md:px-4 md:pt-6
        h-[4rem]
        border-t-2
        md:border-0
        fixed
        bottom-0
        md:relative
        z-50
        md:h-screen
        w-screen
        md:w-[6rem]
        flex
        items-center
        gap-4
        md:flex-col md:gap-0
        justify-center
        md:justify-start
      "
    >
      <div @click="routeUrl()" class="items-end gap-4 hidden md:flex">
        <div
          class="gap-4 items-center justify-center w-max h-max cursor-pointer"
        >
          <svg
            width="41"
            height="29"
            viewBox="0 0 41 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
            <rect
              y="11.543"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
            <rect
              y="23.0859"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
          </svg>
        </div>
        <Transition name="logo-slide">
          <span
            v-if="store.showAll"
            :class="store.showAll ? 'lg:flex' : 'hidden'"
            class="
              hidden
              lg:flex
              N700
              font-bold
              text-2xl
              cursor-pointer
              transition-all
              duration-200
              ease-linear
            "
            >AmaliTech</span
          >
        </Transition>
      </div>
      <span
        @click="store.showAll = !store.showAll"
        class="
          absolute
          hidden
          lg:flex
          h-6
          w-6
          rounded-full
          items-center
          justify-center
          p-2
          shadow-md
          BG0
          top-16
          -right-3
          cursor-pointer
        "
      >
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-else />
      </span>
      <div
        class="hidden md:inline-block bg-[#E6E8F0] h-[1px] w-full mb-4 mt-6"
      ></div>
      <div class="flex md:flex-col gap-[5vw] md:gap-4 h-max md:w-full">
        <NavigationComponent
          @click="setActiveRoute('Dashboard')"
          :item="{ label: 'Dashboard', route: '/' }"
          @mouseover="toggleOnDashboard(true)"
          @mouseout="toggleOnDashboard(false)"
        >
          <DashboardIcon
            :color="
              onDashboard || activeRoute === 'Dashboard' ? '#DD5928' : '#8F95B2'
            "
          />
        </NavigationComponent>
        <NavigationComponent
          v-if="permissions?.VIEW_INVOICES || permissions?.MANAGE_INVOICES"
          @click="setActiveRoute('Invoice')"
          :item="{ label: 'Invoice', route: '/invoice' }"
          @mouseover="toggleOnInvoiceManager(true)"
          @mouseout="toggleOnInvoiceManager(false)"
        >
          <InvoiceManagerIcon
            :color="
              onInvoiceManager || activeRoute === 'Invoice'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>
        <div class="hidden md:inline-block bg-[#E6E8F0] h-[1px] w-full"></div>
        <NavigationComponent
          v-if="
            permissions?.MANAGE_ACCESS_LEVELS ||
            permissions?.MANAGE_INVOICE_MANAGER ||
            permissions?.VIEW_INVOICE_MANAGER ||
            permissions?.VIEW_ACCESS_LEVELS
          "
          @click="setActiveRoute('Settings')"
          :item="{ label: 'Settings', route: '/settings/access-level' }"
          @mouseover="toggleOnSettings(true)"
          @mouseout="toggleOnSettings(false)"
        >
          <SettingsIcon
            :color="
              onSettings || activeRoute === 'Settings' ? '#DD5928' : '#8F95B2'
            "
          />
        </NavigationComponent>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { useAccessStore } from '@/apps/authentication/store/store_access';
import { viewTab } from '@/helpers/newGroupPermissions';
import SettingsIcon from '../assets/SettingsIcon.vue';
import DashboardIcon from '@/assets/DashboardIcon.vue';
import InvoiceManagerIcon from '@/assets/InvoiceManagerIcon.vue';
import NavigationComponent from './NavigationComponent.vue';
import { ref, watch } from 'vue';
import { useStore } from '@/store';
import { useInvoiceStore } from '@/apps/invoice-manager/store/invoiceStore';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import ArrowLeftIcon from '@/assets/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/assets/ArrowRightIcon.vue';
import { getToken } from '@/services/auth';
import { config } from '@/config/config';

const route = useRoute();
const { currentRoute, activeRoute } = storeToRefs(useInvoiceStore());
// user permissions
const emits = defineEmits(['appSelected']);
const Access = useAccessStore();
const appsPerm = Access.permissions.resource_manager;
const store = useStore();
const loginHint: string = store.loginHint;
const { permissions } = storeToRefs(useStore());
const invoiceStore = useInvoiceStore();
const env = process.env;
const viewResource = env.VUE_APP_STAGING
  ? appsPerm.view_talent_pool ||
    appsPerm.view_skills_manager ||
    appsPerm.view_client_manager ||
    appsPerm.view_project_manager
  : viewTab(appsPerm);
const onDashboard = ref(false);
const onInvoiceManager = ref(false);
const onSettings = ref(false);

const toggleOnDashboard = (status: boolean) => {
  onDashboard.value = status;
};
const toggleOnInvoiceManager = (status: boolean) => {
  onInvoiceManager.value = status;
};
const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};

const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}?app-token=${token}&login-hint=${loginHint}`;
  }
};

const setActiveRoute = (route: string) => {
  invoiceStore.setActiveRoute(route);
};

watch(route, (routePath) => {
  if (routePath.fullPath !== currentRoute.value) {
    invoiceStore.setCurrentRoute(routePath.fullPath);
  }

  if (routePath.name === 'Dashboard') {
    setActiveRoute('Dashboard');
  } else if (routePath.name === 'Invoice') {
    setActiveRoute('Invoice');
  } else if (
    routePath.name === 'Invoice Manager' ||
    routePath.name === 'Invoice Access Level'
  ) {
    setActiveRoute('Settings');
  }
});
</script>
<style scoped>
.settings {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #474d66;
  background-color: white;
  scrollbar-width: none;
}
.settings::-webkit-scrollbar {
  display: none;
}
ul {
  margin: 19px 0;
}
li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.H700 {
  z-index: 2;
}
@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}
@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }
  .menu_view {
    width: auto;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: width 0.3s linear;
}

.slide-enter-from,
.slide-leave-to {
  width: 6rem;
}

.logo-slide-enter-active,
.logo-slide-leave-active {
  transition: all 0.3s linear;
}

.logo-slide-enter-from,
.logo-slide-leave-to {
  @apply hidden;
}
</style>
