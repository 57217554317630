export default [
  {
    path: '/departments',
    name: 'departments',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../components/DepartmentComponent.vue'
      )
  },
  {
    path: '/employeedata',
    name: 'EmployeeData',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../components/EmployeeData.vue')
  },
  {
    path: '/positions',
    name: 'positions',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../components/EmployeePosition.vue'
      )
  },
  {
    path: '/employment-types',
    name: 'Employment Types',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../components/EmploymentTypes.vue'
      )
  },
  {
    path: '/idprefix',
    name: 'Id Prefix',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../views/IdPrefix.vue')
  },
  {
    path: '/accesslevel',
    name: 'Access Level',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../views/AccessLevel.vue')
  },
  // {
  //   path: "/configurations",
  //   name: "Configurations",
  //   meta: { auth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "config" */ "../components/Configurations.vue"),
  // },
  // {
  //   path: "/time-sheet-approvals/:id?",
  //   name: "TimeSheets",
  //   meta: { auth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "config" */ "../components/TimeSheets.vue"),
  // },
  {
    path: '/createaccesslevel',
    name: 'Create Access Level',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../views/CreateAccessLevel.vue')
  },
  {
    path: '/seniority-level',
    name: 'Seniority Level',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../views/SeniorityLevel.vue')
  },
  {
    path: '/updateaccesslevel',
    name: 'Update Access Level',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ '../views/CreateAccessLevel.vue')
  }

  // {
  //   path: "/project-config",
  //   name: "Project configuration",
  //   meta: { auth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "config" */ "../../resource-manager/views/ProjectConfiguration.vue"),
  // },
];
