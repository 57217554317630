<template>
  <div
    class="
      absolute
      right-0
      top-[5vh]
      md:top-14
      bg-white
      w-fit
      pl-3
      pr-12
      py-2.5
      shadow-md
      z-10
      rounded
      N800
      P200
    "
  >
    <a
      href="javascript:void(0);"
      class="flex gap-2 mb-4"
      @click.prevent="viewEmployee()"
    >
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a
      class="flex gap-2 cursor-pointer"
      href="javascript:void(0);"
      @click.prevent="userLogout()"
    >
      <img src="@/assets/logout.svg" alt="logout" />
      <span>Logout</span>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from '@/apps/authentication/store/store_access';
import { encodeUrlPath } from '@/helpers/utils';
import { getToken, logout } from '@/services/auth';
import { useStore } from '@/store';

const env = process.env;
const Access = useAccessStore();
const store = useStore();

const loginHint: string = store.loginHint;

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  const token: string | null = getToken();
  location.href = `${hostedURL}view-employee/${encryptedId}/personal?app-token=${token}?login-hint=${loginHint}`;
};

const userLogout = () => {
  Access.$reset();
  // accesslevel.$reset();
  logout().then(() => {
    localStorage.removeItem('app-token');
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store?.loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}/login`;
  });
};
</script>
