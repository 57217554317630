import { reactive } from 'vue';
import { ApolloError } from '@apollo/client';

export const success = reactive({ block: false, title: '', message: '' });

export function extractGraphQlErrorMessages(error: ApolloError): string {
  if (error.graphQLErrors.length > 0) {
    const firstError = error?.graphQLErrors[0]
      ? error?.graphQLErrors[0]
      : error;
    return firstError.message; // Handle validation errors
  } else {
    return 'Unknown error'; // Handle non-GraphQL errors
  }
}

export function nameToInit(name?: string): string {
  const n = name?.split(' ');
  let s = '';
  if (n) {
    for (const element of n) {
      if (element[0]) s += element[0].toUpperCase();
    }
  }
  return s.slice(0, 2);
}
