import PageNotFound from '../../PageNotFound.vue';

export default [
  {
    path: '/',
    name: 'Dashboard',
    meta: { auth: true },
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { auth: true },
    component: () => import('../views/SettingsComponent.vue'),
    children: [
      {
        path: 'access-level',
        name: 'Invoice Access Level',
        meta: { auth: true },
        component: () => import('../views/AccessLevel.vue')
      },
      {
        path: 'invoice-manager',
        name: 'Invoice Manager',
        component: () => import('../views/ManageInvoiceAgents.vue')
      }
    ]
  },

  {
    path: '/create-access-level',
    name: 'Create Invoice Access Level',
    meta: { auth: true },
    component: () => import('../views/CreateAccessLevel.vue'),
    children: [
      {
        path: 'basic-information',
        name: 'Basic Information',
        component: () =>
          import('../views/create-access-level/BasicInformation.vue')
      },
      {
        path: 'choose-permissions',
        name: 'Choose Permissions',
        component: () =>
          import('../views/create-access-level/ChoosePermissions.vue')
      },
      {
        path: 'assign-access-level',
        name: 'Assign Access Level',
        component: () =>
          import('../views/create-access-level/AssignAccessLevel.vue')
      }
    ]
  },
  {
    path: '/invoice-approvers',
    name: 'Invoice Approvers',
    meta: { auth: true },
    component: () => import('../views/InvoiceApproversView.vue')
  },
  {
    path: '/rate',
    name: 'Rate',
    meta: { auth: true },
    component: () => import('../views/RateView.vue')
  },

  // Main invoice manager routes
  {
    path: '/invoice',
    name: 'Invoice',
    meta: { auth: true },
    component: () => import('../views/BillingOrganisationsPage.vue')
  },

  // Clients overview (Invoice manager) routes
  {
    path: '/invoice/:id',
    name: 'Clients Overview',
    meta: { auth: true },
    component: () => import('../views/ClientsOverview.vue')
  },

  {
    path: '/clients-overview/:id',
    name: 'clientProject',
    meta: { auth: true },
    component: () => import('../views/ClientProject.vue')
  },
  {
    path: '/client-projects/:id',
    name: 'invoiceList',
    meta: { auth: true },
    component: () => import('../views/InvoiceList.vue')
  },
  {
    path: '/edit-invoice/:id',
    name: 'editInvoice',
    meta: { auth: true },
    component: () => import('../views/CreateInvoice.vue')
  },
  {
    path: '/generate-invoice/:id',
    name: 'generateInvoice',
    component: () => import('../views/GenerateInvoice.vue')
  },

  {
    path: '/update-invoice-status/:id',
    name: 'updateInvoiceStatus',
    meta: { auth: true },
    component: () => import('../views/GenerateInvoice.vue')
  },

  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    meta: { auth: true },
    component: PageNotFound
  }
];
