import { reactive } from 'vue';

interface AlertStateType {
  display: boolean;
  severity: 'success' | 'error';
  title: string;
  message: string;
}

export const alertState = reactive<AlertStateType>({
  display: false,
  severity: 'success',
  title: '',
  message: ''
});
