<template>
  <div
    class="
      N700
      flex
      justify-center
      sm:justify-end
      items-end
      h-[6vh]
      md:h-[7.5vh]
      z-50
      relative
      pb-0.5
    "
    @mouseleave="removeDropdowns"
  >
    <div class="flex gap-4">
      <div
        class="
          bg-[#D8DAE5]
          text-[#2F365A]
          flex
          items-center
          gap-2
          self-center
          cursor-pointer
          rounded-lg
          p-2
        "
        @click="showAppsDropDown = !showAppsDropDown"
      >
        <AppsSvg color="#101840" />
        <span class="text-[#101840]">Apps</span>
      </div>

      <div
        class="flex gap-3 items-center cursor-pointer"
        @click="showProfileDropdown = !showProfileDropdown"
      >
        <div
          :class="profilePicture ? '' : 'border-2 border-[#8F95B2]'"
          class="rounded-full"
        >
          <ProfileImageComponent
            :initials="nameToInit(userName)"
            :img-src="profilePicture"
            :name="userName"
            :widthSize="isMobileScreen ? '7vw' : '2.5rem'"
            :heightSize="isMobileScreen ? '7vw' : '2.5rem'"
            border="2px solid"
            text-size="text-base"
          />
        </div>

        <p class="self-center N800 username line-clamp-1 max-w-[12rem]">
          {{ user.first_name }}
        </p>
        <img
          alt="arrow-down-svg"
          src="@/assets/arrow-down.svg"
          class="h-fit self-center transition-all duration-300 ease-linear"
          :class="showProfileDropdown ? 'transform rotate-180' : ''"
        />
      </div>
      <ProfileDropdown v-if="showProfileDropdown" />
      <AppsNavigation v-if="showAppsDropDown" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from '@/store';
import ProfileDropdown from './ProfileDropdown.vue';
import AppsNavigation from './AppsNavigation.vue';
import AppsSvg from '@/assets/menu-icons/AppsSvg.vue';
import ProfileImageComponent from '@/components/Avatar/ProfileImageComponent.vue';
import { nameToInit } from '@/apps/invoice-manager/helpers/utils';
import { storeToRefs } from 'pinia';

const { user } = storeToRefs(useStore());
const profilePicture = user.value.profile_image;
const userName = computed(
  () => user.value.first_name + ' ' + user.value.last_name
);

const showProfileDropdown = ref<boolean>(false);

const isMobileScreen = ref(false);
const showAppsDropDown = ref(false);

const removeDropdowns = () => {
  showProfileDropdown.value = false;
  showAppsDropDown.value = false;
};

const handleResize = () => {
  isMobileScreen.value = window.innerWidth < 600;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
});
</script>
<style>
@media (max-width: 420px) {
  .username {
    display: none;
  }
}
</style>
