import { defineStore } from 'pinia';
import {
  ClientDetails,
  EmailRecipient,
  EmployeeAgent,
  Organization,
  Project,
  ProjectInvoiceDetails
} from '../helpers/types';

export const useEmployeeStore = defineStore('employees', {
  state: () => ({
    allEmployees: [] as EmployeeAgent[]
  }),
  actions: {
    setAllEmployeeRecords(employees: EmployeeAgent[]) {
      this.allEmployees = employees;
    }
  },
  persist: true
});

export const useInvoiceStore = defineStore('invoice', {
  state: () => ({
    currentRoute: '/',
    previousRoute: '',
    activeRoute: '',
    parentOrganizationId: 0,
    organizationDetails: {} as Organization,
    clientDetails: {} as ClientDetails,
    projectDetails: {} as Project
  }),

  actions: {
    setParentOrganizationId(id: number) {
      this.parentOrganizationId = id;
    },
    setOrganizationDetails(organizationDetails: Organization) {
      this.organizationDetails = organizationDetails;
    },

    setClientDetails(clientDetails: ClientDetails) {
      this.clientDetails = clientDetails;
    },
    setProjectDetails(projectDetails: Project) {
      this.projectDetails = projectDetails;
    },
    setCurrentRoute(route: string) {
      this.previousRoute = this.currentRoute;
      this.currentRoute = route;
    },
    setActiveRoute(route: string) {
      this.activeRoute = route;
    }
  },
  persist: true
});

export const useInvoiceStatus = defineStore({
  id: 'invoiceStatus',
  state: () => ({
    newInvoice: true,
    invoiceId: '',
    updateStatus: false,
    showPaidIcon: false,
    confirmedPayment: false,
    isSent: false,
    invoiceUrl: '',
    invoiceEmails: [] as { client_email: string }[]
  }),

  actions: {
    setInvoiceStatus(status: boolean) {
      this.newInvoice = status;
    },
    setInvoiceId(id: string) {
      this.invoiceId = id;
    },
    resetInvoiceStatus() {
      this.newInvoice = true;
      this.invoiceId = '';
    },
    setUpdateStatus(status: boolean) {
      this.updateStatus = status;
    },
    setShowPaidIcon(status: boolean) {
      this.showPaidIcon = status;
    },
    setConfirmedPayment(status: boolean) {
      this.confirmedPayment = status;
    },
    setInvoiceUrl(url: string) {
      this.invoiceUrl = url;
    },
    setInvoiceEmails(emails: EmailRecipient[]) {
      this.invoiceEmails = emails;
    },
    setInvoiceSent(status: boolean) {
      this.isSent = status;
    }
  },
  persist: true
});

export const useProjectData = defineStore({
  id: 'invoiceProjectData',
  state: () => ({
    storeProjectData: {} as ProjectInvoiceDetails
  }),
  actions: {
    setProjectData(projectData: ProjectInvoiceDetails) {
      this.storeProjectData = projectData;
    },
    resetProjectData() {
      this.storeProjectData = {
        project_id: 0,
        project_name: '',
        project_number: 0,
        client_id: 0
      };
    }
  },
  persist: true
});
